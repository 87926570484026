import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { Suspense, lazy, useState, useEffect } from 'react';
import axios from "axios";
import Context from './Context';

const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));
const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Categoria = lazy(() => import('./Pages/Categoria'));
const Contato = lazy(() => import('./Pages/Contato'));
const NotFound = lazy(() => import('./Pages/NotFound'));


function App() {
  localStorage.setItem("apiUrl", "/sistema/");
  const [categorias, setCategorias] = useState([]);
  const [banners, setBanners] = useState([]);
  const [configuracoes, setConfiguracoes] = useState({});
  const [modal, setModal] = useState(false);
  const value = { configuracoes, setConfiguracoes, modal, setModal, setCategorias, categorias, setBanners, banners };

  useEffect(() => {
    axios.get(`${localStorage.getItem("apiUrl")}/api/configuracoes`).then(response => {
      if (response.data.status === 200) {
        setConfiguracoes(response.data.success.configuracoes);
        setCategorias(response.data.success.categorias);
        setBanners(response.data.success.banners);
      } else {
        setModal({
          titulo: "Ocorreu um erro!",
          texto: "Por favor, recarregue a página!",
          botao: "Voltar ao site"
        })
      }
    });
  }, []);

  return (
    <Context.Provider value={value}>
      <div id="modal" className={modal ? "active" : ""}>
        {modal && <div className="content">
          <h3>{modal.titulo}</h3>
          <p>{modal.texto}</p>
          <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
        </div>}
      </div>
      <Router>
        <div className="App">
          <Suspense fallback={<div></div>}>
            <Header />
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/empresa" exact>
                <Empresa />
              </Route>
              <Route path="/produtos/:cat" exact>
                <Categoria />
              </Route>
              <Route path="/produtos" exact>
                <Produtos />
              </Route>
              <Route path="/produto/:slug" exact>
                <Produto />
              </Route>
              <Route path="/contato" exact>
                <Contato />
              </Route>
              <Route component={NotFound} />
            </Switch>
            <Footer />
            <Cookies />
          </Suspense>
        </div>
      </Router>
    </Context.Provider>
  );
}

export default App;
