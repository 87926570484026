import React from 'react';

const Context = React.createContext({
    configuracoes: {},
    setConfiguracoes: () => { },
    categorias: {},
    setCategorias: () => { },
    banners: {},
    setBanners: () => { },
    modal: false,
    setModal: () => { },
    activePage: false,
    setActivePage: () => { }
});

export default Context;